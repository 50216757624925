<template>
	<div :style="styleObject" class="scale-box">
		<div class="header-box">
			<div class="header">
				<img src="../assets/header_lf.png" class="header-img">
				<span class="header-name">XXX工程管理平台</span>
				<img src="../assets/header_rt.png" class="header-img">
			</div>
			<div class="date-box">
				<div class="times-box">
					{{times}}
				</div>
				<div class="dates">
					{{dates}}
				</div>
			</div>
		</div>
		<div class="content-box">
			<div class="con-lf">
				<div class="con-h">
					项目概况
				</div>
				<div class="project-infor">
					<div class="infor-txt">
						项目名称：XXX工程管理平台
					</div>
					<div class="infor-txt">
						所属公司：上海聚倍物联网科技有限公司
					</div>
					<div class="infor-txt">
						项目描述：本工程施工范围主要有：转输隧洞工程、生态工程、宝石湖工程、溢洪道工程、截洪渠工程、水土保育区工程
					</div>
				</div>
				<div class="con-h">
					在线人员总览
				</div>
				<div class="personnel-box">
					<div class="personnel-date">
						更新时间：2021/8/27 11:01:04
					</div>
					<div class="personnel-echart">
						<div id="myChart" :style="{width: '80px', height: '80px'}"></div>
						<div id="userChart" :style="{width: '80px', height: '80px'}"></div>
					</div>
					<div class="echart-txt-box">
						<div>在场人数</div>
						<div>总人数</div>
					</div>
					<div class="personnel-num">
						<div class="num-lf">
							<span class="num-txt">一般作业人员</span>
							<span class="num-box">2</span>
						</div>
						<div class="num-lf">
							<span class="num-txt">特种作业人员</span>
							<span class="num-box">2</span>
						</div>
					</div>
					<div class="personnel-num">
						<div class="num-lf">
							<span class="num-txt">项目管理岗位</span>
							<span class="num-box">2</span>
						</div>
						<div class="num-lf">
							<span class="num-txt">其他</span>
							<span class="num-box">2</span>
						</div>
					</div>
				</div>
				<div class="user-box">
					<div class="user-h">
						<div class="con-h-txt">上班</div>
						<div class="con-h-txt">下班</div>
					</div>
					<div class="user-con">
						<vue-seamless-scroll :data="userList" class="seamless-warp" :class-option="classOption">
							<ul class="user-con-box">
								<li class="user-list" v-for="(item,index) in userList" :key="index">
									<img src="../assets/photo.png" class="photo">
									<div class="user-infor">
										<div class="user-infor-list">
											{{item.time}}
										</div>
										<div class="user-infor-list">
											{{item.type}}
										</div>
										<div class="user-infor-list">
											{{item.name}}
										</div>
									</div>
								</li>
							</ul>
						</vue-seamless-scroll>
						<vue-seamless-scroll :data="userListdown" class="seamless-warp" :class-option="classOption">
							<ul class="user-con-box">
								<li class="user-list" v-for="(item,index) in userListdown" :key="index">
									<img src="../assets/photo.png" class="photo">
									<div class="user-infor">
										<div class="user-infor-list">
											{{item.time}}
										</div>
										<div class="user-infor-list">
											{{item.type}}
										</div>
										<div class="user-infor-list">
											{{item.name}}
										</div>
									</div>
								</li>
							</ul>
						</vue-seamless-scroll>
					</div>
				</div>
			</div>
			<div class="con-cn">
				<div class="con-h">
					视频监控
				</div>
				<div class="radio-box">
					<!-- <div id="mse"></div> -->
					<video style="width: 900px; height: 520px; margin-top:-10px" ref="monitorVideo">
					</video>
				</div>
				<div class="radio-name">工地实况监控</div>
				<div class="con-h">
					在线车辆进出总览
				</div>
				<div class="car-box">
					<div class="car-h">
						<div class="car-h-td">车牌号码</div>
						<div class="car-h-td">进场时间</div>
						<div class="car-h-td">出场时间</div>
						<div class="car-h-td">进场图片</div>
						<div class="car-h-td">出场图片</div>
					</div>
					<div class="car-tab">
						<div class="car-tr" v-for="(item,index) in carTablist" :key="index">
							<div class="car-td">{{item.carId}}</div>
							<div class="car-td">{{item.startTime}}</div>
							<div class="car-td">{{item.endTime}}</div>
							<div class="car-td"><img src="../assets/car.png" class="car-img"></div>
							<div class="car-td"><img src="../assets/car.png" class="car-img"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="con-rt">
				<div class="con-h">
					环境监控
				</div>
				<div class="dust-box">
					<div class="dust-h">
						xxx工地项目扬尘
					</div>
					<div class="dust-list-box">
						<div class="dust-tab">
							<div class="dust-tr">
								<div class="dust-td">
									<span class="dust-color1">25</span>
									<span class="dust-txt-b">温度</span>
								</div>
								<div class="dust-td">
									<span class="dust-color2">优</span>
									<span class="dust-txt-b">空气质量</span>
								</div>
								<div class="dust-td">
									<span class="dust-color3">轻风</span>
									<span class="dust-txt-b">风级</span>
								</div>
							</div>

							<div class="dust-tr tr-p">
								<div class="dust-td">
									<img src="../assets/dust_icon1.png" class="dust-img">
									<span class="dust-txt">33μg/m³</span>
									<span class="dust-txt">pm2.5</span>
								</div>
								<div class="dust-td">
									<img src="../assets/dust_icon2.png" class="dust-img">
									<span class="dust-txt">12μg/m³</span>
									<span class="dust-txt">pm10</span>
								</div>
								<div class="dust-td">
									<img src="../assets/dust_icon3.png" class="dust-img">
									<span class="dust-txt">23°C</span>
									<span class="dust-txt">温度</span>
								</div>
								<div class="dust-td">
									<img src="../assets/dust_icon4.png" class="dust-img">
									<span class="dust-txt">23%</span>
									<span class="dust-txt">湿度</span>
								</div>
							</div>
							<div class="dust-tr tr-p">
								<div class="dust-td">
									<img src="../assets/dust_icon5.png" class="dust-img">
									<span class="dust-txt">23db</span>
									<span class="dust-txt">噪音</span>
								</div>
								<div class="dust-td">
									<img src="../assets/dust_icon6.png" class="dust-img">
									<span class="dust-txt">3级</span>
									<span class="dust-txt">风力</span>
								</div>
								<div class="dust-td">
									<img src="../assets/dust_icon7.png" class="dust-img">
									<span class="dust-txt">3m/s</span>
									<span class="dust-txt">风速</span>
								</div>
								<div class="dust-td">
									<img src="../assets/dust_icon8.png" class="dust-img">
									<span class="dust-txt">东南风</span>
									<span class="dust-txt">风向</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="con-h">
					电箱监控
				</div>
				<div class="electric-box">
					<div class="dust-h">
						尚无设备连接
					</div>
					<div class="dust-list-box">
						<div class="dust-tab">
							<div class="dust-tr tr-p">
								<div class="dust-td lg">
									<img src="../assets/electric_icon1.png" class="dust-img">
									<span class="dust-txt">-°C</span>
									<span class="dust-txt">线缆温度A</span>
								</div>
								<div class="dust-td lg">
									<img src="../assets/electric_icon2.png" class="dust-img">
									<span class="dust-txt">-°C</span>
									<span class="dust-txt">线缆温度b</span>
								</div>
								<div class="dust-td lg">
									<img src="../assets/electric_icon3.png" class="dust-img">
									<span class="dust-txt">-°C</span>
									<span class="dust-txt">线缆温度c</span>
								</div>
								<div class="dust-td lg">
									<img src="../assets/electric_icon4.png" class="dust-img">
									<span class="dust-txt">-°C</span>
									<span class="dust-txt">环境温度</span>
								</div>
								<div class="dust-td lg">
									<img src="../assets/electric_icon5.png" class="dust-img">
									<span class="dust-txt">-mA</span>
									<span class="dust-txt">漏电流</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="con-h">
					塔吊监控
				</div>
				<div class="crane-box">
					<div class="dust-h">
						尚无设备连接
					</div>
					<div class="dust-list-box">
						<div class="dust-tab">
							<div class="dust-tr tr-p">
								<div class="dust-td lg">
									<img src="../assets/crane_icon1.png" class="dust-img">
									<span class="dust-txt">-t</span>
									<span class="dust-txt">吊重</span>
								</div>
								<div class="dust-td lg">
									<img src="../assets/crane_icon2.png" class="dust-img">
									<span class="dust-txt">-h</span>
									<span class="dust-txt">高度</span>
								</div>
								<div class="dust-td lg">
									<img src="../assets/crane_icon3.png" class="dust-img">
									<span class="dust-txt">-%</span>
									<span class="dust-txt">力矩百分比</span>
								</div>
								<div class="dust-td lg">
									<img src="../assets/crane_icon4.png" class="dust-img">
									<span class="dust-txt">-°C</span>
									<span class="dust-txt">倾角百分比</span>
								</div>
								<div class="dust-td lg">
									<img src="../assets/crane_icon5.png" class="dust-img">
									<span class="dust-txt">-°</span>
									<span class="dust-txt">倾角</span>
								</div>
							</div>

							<div class="dust-tr tr-p">
								<div class="dust-td lg">
									<img src="../assets/crane_icon6.png" class="dust-img">
									<span class="dust-txt">-m/s</span>
									<span class="dust-txt">风速</span>
								</div>
								<div class="dust-td lg">
									<img src="../assets/crane_icon7.png" class="dust-img">
									<span class="dust-txt">-%</span>
									<span class="dust-txt">风速百分比</span>
								</div>
								<div class="dust-td lg">
									<img src="../assets/crane_icon8.png" class="dust-img">
									<span class="dust-txt">-°</span>
									<span class="dust-txt">回转角度</span>
								</div>
								<div class="dust-td lg">
									<img src="../assets/crane_icon9.png" class="dust-img">
									<span class="dust-txt">-m</span>
									<span class="dust-txt">幅度</span>
								</div>
								<div class="dust-td lg">
									<img src="../assets/crane_icon10.png" class="dust-img">
									<span class="dust-txt">-</span>
									<span class="dust-txt">预警</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import debounce from "lodash.debounce"
	import vueSeamlessScroll from 'vue-seamless-scroll'
	import WebRtcStreamer from "../../public/webrtcstreamer.js"
	let webRtcServer = null;
	export default {
		name: 'Home',
		data() {
			return {
				width: 1920,
				height: 1080,
				scale: {
					ww: 1,
					wh: 1
				},
				dates: '',
				dateTime: '',
				times: '',
				userListdown: [{ //下班人员
						photo_img: "../assets/photo.png",
						name: '李紫逸',
						type: '项目管理班组',
						time: '07:11:32'
					},
					{
						photo_img: "../assets/photo.png",
						name: '李紫逸',
						type: '项目管理班组',
						time: '07:11:32'
					},
					{
						photo_img: "../assets/photo.png",
						name: '李紫逸',
						type: '项目管理班组',
						time: '07:11:32'
					},
					{
						photo_img: "../assets/photo.png",
						name: '李紫逸',
						type: '项目管理班组',
						time: '07:11:32'
					},
					{
						photo_img: "../assets/photo.png",
						name: '李紫逸',
						type: '项目管理班组',
						time: '07:11:32'
					},
					{
						photo_img: "../assets/photo.png",
						name: '李紫逸',
						type: '项目管理班组',
						time: '07:11:32'
					}
				],
				userList: [{ //上班人员
						photo_img: "../assets/photo.png",
						name: '李紫逸',
						type: '项目管理班组',
						time: '07:11:32'
					},
					{
						photo_img: "../assets/photo.png",
						name: '李紫逸',
						type: '项目管理班组',
						time: '07:11:32'
					},
					{
						photo_img: "../assets/photo.png",
						name: '李紫逸',
						type: '项目管理班组',
						time: '07:11:32'
					},
					{
						photo_img: "../assets/photo.png",
						name: '李紫逸',
						type: '项目管理班组',
						time: '07:11:32'
					},
					{
						photo_img: "../assets/photo.png",
						name: '李紫逸',
						type: '项目管理班组',
						time: '07:11:32'
					},
					{
						photo_img: "../assets/photo.png",
						name: '李紫逸',
						type: '项目管理班组',
						time: '07:11:32'
					}
				],
				carTablist: [{
						carId: '沪A.3121',
						startTime: '07:11:32',
						endTime: '07:11:32',
						startImg: '',
						endImg: ''
					},
					{
						carId: '沪A.3121',
						startTime: '07:11:32',
						endTime: '07:11:32',
						startImg: '',
						endImg: ''
					}
				]
			}
		},
		computed: {
			styleObject() {
				let obj = {
					transform: `scale(${this.scale.ww},${this.scale.wh}) translate(-50%, -50%)`,
					WebkitTransform: `scale(${this.scale.ww},${this.scale.wh}) translate(-50%, -50%)`,
					width: this.width + "px",
					height: this.height + "px",
				}
				return obj
			},
			classOption() {
				return {
					step: 0.36, // 数值越大速度滚动越快
					limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: false, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
				}
			}
		},
		destroyed() {
			webRtcServer.disconnect()
		},
		components: {
			vueSeamlessScroll
		},
		created() {
			this.dateTime = setInterval(() => {
				const date = new Date()
				var year = date.getFullYear()
				// 月
				var month = date.getMonth() + 1
				// 日
				var day = date.getDate()
				// 时
				var hh = date.getHours()
				if (hh < 10) {
					hh = '0' + hh
				}
				// 分
				var mm = date.getMinutes()
				if (mm < 10) {
					mm = '0' + mm
				}
				// 秒
				var ss = date.getSeconds()
				if (ss < 10) {
					ss = '0' + ss
				}
				var days = date.getDay()
				if (days == 1) {
					days = '一'
				} else if (days == 2) {
					days = '二'
				} else if (days == 3) {
					days = '三'
				} else if (days == 4) {
					days = '四'
				} else if (days == 5) {
					days = '五'
				} else if (days == 6) {
					days = '六'
				} else if (days == 7) {
					days = '天'
				}
				this.times = hh + ':' + mm + ':' + ss
				this.dates = year + '年' + month + '月' + day + '日 ' + '星期' + days
			}, 1000)

		},
		mounted() {
			this.scale = this.getScale()
			window.addEventListener("resize", this.setScale)
			this.drawLine();
			console.log(location.protocol + "//" + window.location
				.hostname + ":8000")
			webRtcServer = new WebRtcStreamer(this.$refs.monitorVideo, location.protocol + "//" + window.location
				.hostname + ":8000");
			webRtcServer.connect("rtsp://192.168.3.213:8554/test1");
		},
		methods: {
			getScale() {
				// 固定好16:9的宽高比，计算出最合适的缩放比，宽高比可根据需要自行更改
				let scales = {}
				scales.ww = window.innerWidth / this.width
				scales.wh = window.innerHeight / this.height
				return scales
			},

			setScale: debounce(function() {
				// 获取到缩放比，设置它
				let scales = this.getScale()
				this.scale = scales
			}, 500),
			drawLine() {
				// 基于准备好的dom，初始化echarts实例
				let myChart = this.$echarts.init(document.getElementById('myChart'))
				// 绘制图表
				myChart.setOption({
					value: 60, //百分比,必填
					name: '在场人数', //必填
					color: ['#0EFCFF', '#6076AD'],
					fontSize: 18,
					title: {},
					series: [{
						name: '来源',
						type: 'pie',
						radius: ['80%', '90%'],
						avoidLabelOverlap: false,
						hoverAnimation: false,
						label: {
							normal: {
								show: false,
								position: 'center',
								textStyle: {
									fontWeight: 'bold'
								},
							}
						},
						data: [{
								value: 60,
								name: 60,
								label: {
									normal: {
										show: true,
										textStyle: {
											fontSize: 20,
											fontWeight: 'bold'
										},
									}
								}
							},
							{
								value: 40,
								name: ''
							}
						]
					}]
				});

				//userChart
				let userChart = this.$echarts.init(document.getElementById('userChart'))
				// 绘制图表
				userChart.setOption({
					value: 180, //百分比,必填
					name: '总人数', //必填
					color: ['#FFD403', '#f2f2f2'],
					fontSize: 18,
					title: {},
					series: [{
						name: '来源',
						type: 'pie',
						radius: ['80%', '90%'],
						avoidLabelOverlap: false,
						hoverAnimation: false,
						label: {
							normal: {
								show: false,
								position: 'center',
								textStyle: {
									fontWeight: 'bold'
								},
							}
						},
						data: [{
								value: 180,
								name: 180,
								label: {
									normal: {
										show: true,
										textStyle: {
											fontSize: 20,
											fontWeight: 'bold'
										},
									}
								}
							},
							{
								value: 0,
								name: ''
							}
						]
					}]
				});
			}
		},
		beforeDestroy() {
			window.addEventListener("resize", this.setScale)
		}
	}
</script>
<style>
	@import url("index.css");
</style>
